import { useState, useEffect } from "react";
import AxiosInstance from "../Axios";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import AddItemGallery from "./AddItemGallery";
import BasicModal from 'src/components/Modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from "../myComponents/MyToactify";
import { CButton } from "@coreui/react";

const GalleryList = ({ gallery_id, updateLists, setUpdateLists }) => {

  const urlApi_1 = "/gallery_items";
  const popup = MyToastify();

  const [galleryList, setGalleryList] = useState([]);
  const [selectedData, setSelectedData] = useState(null); // Вибрана галерея для редагування
  const [showModalItemUpdate, setShowModalItemUpdate] = useState(false); // Показати модальне вікно для оновлення галереї

  // Функція для відкривання модального вікна та оновлення даних
  const actionShowModalItemUpdate = (rez) => {
    setShowModalItemUpdate(rez);
    if (!rez) {
      setSelectedData(rez);
      setUpdateLists(true);
    };
  };

  // Функція для запиту за даними галереї
  const getGalleryItems = async () => {
    try {
      const response = await AxiosInstance.get(urlApi_1, { params: { gallery: gallery_id } });
      // console.log('gallery_items get response', response);
      setGalleryList(response.data['hydra:member']);
    } catch (error) {
      console.error("Помилка запиту за даними галереї", error);
    } finally {
      setUpdateLists(false);
    };
  };

  // Оновити галерею
  useEffect(() => {
    if (gallery_id && updateLists) {
      getGalleryItems();
    };
  }, [gallery_id, updateLists])

  // Відкривати вибрану галерею для редагування
  useEffect(() => {
    if (selectedData) {
      setShowModalItemUpdate(true);
    };
  }, [selectedData]);

  return (
    <>
      <CardGroup>
        {
          galleryList.map((val) => {
            return (
              <Card style={{ maxWidth: '400px' }} key={val.id}>
                {(val.media != undefined && val.media.contentUrl != undefined) && <Card.Img variant="top" src={process.env.REACT_APP_SERVER_URL + val.media.contentUrl} />}
                {(val.urlPhoto != undefined && val.urlPhoto != null && val.urlPhoto != '') && <Card.Img variant="top" src={val.urlPhoto} />}
                {(val.urlVideo != undefined && val.urlVideo != null && val.urlVideo != '') && <video src={val.urlVideo} style={{ width: '100%', height: '300px' }}></video>}

                <Card.Body>
                  <Card.Title>{val.name}</Card.Title>
                  <Card.Text>{val.content}</Card.Text>
                </Card.Body>
                <Card.Footer>
                  <CButton color='secondary' onClick={() => setSelectedData(val)} style={{ cursor: "pointer" }} title="Редагувати">
                    🖊 редагувати
                  </CButton>
                </Card.Footer>
              </Card>
            )
          })
        }
      </CardGroup>

      {/* Модальне вікно з вибраною галереєю */}
      {
        (selectedData && showModalItemUpdate) &&
        <div style={{ display: 'none' }}> {/* приховати кнопку відкритого модуля */}
          <BasicModal
            show={showModalItemUpdate}
            actionShowModal={actionShowModalItemUpdate}
            title={`Додати  - ${selectedData.name}`}
            btn_name={<>🖊 редагувати</>}
            content={
              <AddItemGallery toastifySuccess={popup.toastifySuccess} parent_id={gallery_id} data={selectedData} actionShowModal={actionShowModalItemUpdate} />
            }
          />
        </div>
      }
    </>
  );
};

export default GalleryList;