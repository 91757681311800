import { useEffect, useState } from "react";
import MediaFrom from "../media/MediaFrom";
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CFormTextarea
} from '@coreui/react';
import AxiosInstance from "../Axios";



const AddItemGallery = ({ toastifySuccess, data, parent_id, actionShowModal }) => {
  const [media, setMedia] = useState((data != undefined && data.media != undefined) ? data.media.id : false);
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [urlPhoto, setUrlPhoto] = useState((data != undefined) ? data.urlPhoto : '');
  const [urlVideo, setUrlVideo] = useState((data != undefined) ? data.urlVideo : '');
  const [content, setContent] = useState((data != undefined) ? data.content : '');
  const [mediaData, setMediaData] = useState(false);
  useEffect(() => {
    if (media != false)
      AxiosInstance.get(`/media_objects/${media}`).then((responce) => {
        if (responce.status == 200) {
          setMediaData(responce.data);
        }
      });
  }, [media]);

  const handleSubmit = event => {
    event.preventDefault();

    const article = {
      name: name,
      content: content,
      gallery: `/api/galleries/${parent_id}`,
      // media: `/api/media_objects/${media}`,
      urlPhoto: urlPhoto,
      urlVideo: urlVideo

    };
    if (media != false)
      article['media'] = `/api/media_objects/${media}`;
    if (data != undefined) {
      const response = AxiosInstance.put('/gallery_items/' + data.id, article).then((response) => {
        if (response.status == 200) {
          toastifySuccess('Галерею оновлено');
          // actionShowModal(setShowModal(!rez), getItems()); перший параметр (закрити модальне вікно), другий - оновити дані)
          actionShowModal(false, true);
        }
      });
    } else {
      const response = AxiosInstance.post('/gallery_items', article).then((response) => {
        // console.log(response);
        if (response.status == 201) {
          // actionShowModal(setShowModal(!rez), getItems()); перший параметр (закрити модальне вікно), другий - оновити дані)
          actionShowModal(false, true);
          toastifySuccess("Галерею створено")
        }
      });
    }

  }
  return (<>
    {(mediaData) && <img src={process.env.REACT_APP_SERVER_URL + mediaData.contentUrl} with="300px" />}
    <MediaFrom type="category" setNewMediaId={setMedia} toastifySuccess={toastifySuccess} />
    <hr />
    <CForm onSubmit={handleSubmit}>
      <div className="mb-3">
        <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
        <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="mb-3">
        <CFormLabel htmlFor="exampleFormControlInput1">Url зображення</CFormLabel>
        <CFormInput type="text" id="exampleFormControlInput1" value={urlPhoto} onChange={(e) => setUrlPhoto(e.target.value)} />
      </div>
      <div className="mb-3">
        <CFormLabel htmlFor="exampleFormControlInput1">Url відео</CFormLabel>
        <CFormInput type="text" id="exampleFormControlInput1" value={urlVideo} onChange={(e) => setUrlVideo(e.target.value)} />
      </div>
      <div className="mb-3">
        <CFormLabel htmlFor="exampleFormControlInput1">Контент</CFormLabel>
        <CFormTextarea id="exampleFormControlInput1" value={content} onChange={(e) => setContent(e.target.value)} row="5" />
      </div>
      <CButton type="submit" color="success">Зберегти</CButton>

    </CForm>
  </>);
}

export default AddItemGallery;